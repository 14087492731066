module.exports = [
  {
    key: 'tech',
    title: 'Tech',
    slug: 'technology'
  },
  {
    key: 'news',
    title: 'News',
    slug: 'news'
  },
  {
    key: 'design',
    title: 'Design',
    slug: 'co-design'
  },
  {
    key: 'worklife',
    title: 'Work Life',
    slug: 'work-life'
  }
];
