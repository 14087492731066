import { trackGAEvent } from '../../../client/utils/third_party_trackers/ga';
import EventModel from '../../../utils/tracking/models';

const clickEvent = (i, channel, article) => {
  // [DataLayer] trigger nav item click event
  const recircItemClickEvent = new EventModel(
    `Recirculation Item Click | ${article.id}`,
    `recirc | ${channel.title} > ${article.id}`,
    'bottomRecirc',
    `bottomRecirc | ${channel.title} > ${article.id}`,
    i + 1
  );

  recircItemClickEvent.triggerTrackingEvent(
    'default-click',
    recircItemClickEvent.getTrackingObject()
  );

  trackGAEvent({
    eventCategory: 'Engagement',
    eventAction: `RecirculationModule:Pos-${i + 1}`,
    eventLabel: `${channel.title}:${article.link}`
  });
};

export default clickEvent;
