import React from 'react';
import PropTypes from 'prop-types';
import clickEvent from './click_event';
import data from './recirc_data';
import clsx from 'clsx';

const Recirc = ({ feed }) => (
  <section className="article-recirculation">
    {data.map(channel => (
      <div className="article-recirculation_column" key={channel.key}>
        <a
          href={`/${channel.slug}`}
          className="article-recirculation_column--header"
        >
          {channel.title}
        </a>

        {feed[channel.key].map((article, i) => {
          const isPremium = article?.premium || false;

          return (
            <a
              href={`/${article.id}/${article.slug}`}
              data-eyebrow={channel.title}
              className={clsx(
                'article-recirculation_article',
                isPremium && 'premium'
              )}
              onClick={() => clickEvent(i, channel, article)}
            >
              {article.title}
            </a>
          );
        })}
      </div>
    ))}
  </section>
);

Recirc.propTypes = {
  feed: PropTypes.shape({}).isRequired
};

export default Recirc;
